@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700&subset=cyrillic,cyrillic-ext,latin-ext,vietnamese");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

body {
  background: #efefef !important;
  font-size: 14px !important;
  font-weight: 500;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.btn-reset {
  padding: 7px 10px;
  margin-bottom: 6px;
}

.range-wrapper-width {
  width: 240px;
}

.min-width-140 {
  min-width: 140px;
  font-size: 12px;
}

small.cm_input_info {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: #b5b5b5;
}

.cm_slect_year_wrapper>span {
  display: inline-block;
  vertical-align: middle;
}

.upload_button {
  margin-left: 87px;
  margin-top: 11px;
}
.upload_btn-spacing{
  padding-top: 10px;
  padding-bottom: 10px;
}
.cm_slect_year_wrapper {
  margin: 25px 0;
}

.date_select {
  position: sticky;
  top: 0;
}

.from-group {
  position: relative;
}

.login-wrapper .card-title {
  text-align: center;
}

.login-wrapper .card-title h3 {
  margin: 25px 0 40px;
}

.login-wrapper img {
  max-width: 60px;
}

.login-wrapper {
  height: 100vh;
  min-height: 500px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background: linear-gradient(to right, #6f69aa, #378ac5);
  .toggle-login-option{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    .login-option{
      margin-bottom: 0;
    }
    .custom-control{
      margin: 0 5px;
    }
  }
  .containerStyle{
    margin-top: 30px;
    display: flex;
    justify-content: center;
    &>div{
    }
    .otp-input-style{
      width: 50px !important;
      border-radius: 10px;
      padding: 10px;
      color: #000000;
      border: 1px solid #000000;
      outline: 0;
    }
  }
}

.popup-overlay {
  overflow: auto;
  padding: 55px 0;
}

.popup-content {
  background: transparent !important;
  border: 0 !important;
  // max-width: 550px;
  width: 100%;
}

.cm_modal {
  max-width: 550px;
  background: white;
  padding: 25px 15px 35px;
  margin: 0 auto;
  border-radius: 15px;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
  position: relative;
}

.error_message {
  color: red;
}

.session_expired {
  z-index: 9999999 !important;
}
.min-width-150 {
  min-width: 150px;
}
.font11{
  font-size: 11px;
}
.cm_modal_close {
  position: absolute;
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  right: -7px;
  top: -9px;
  color: #848484 !important;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.cm_modal_close:hover {
  text-decoration: none;
}

ol.breadcrumb {
  margin: -20px -20px 10px -20px;
  background: white;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
  padding: 5px 10px;
  .breadcrumb-item{
    a{
      color: #0d5a6c;
    }
  }
}

.detail-dispay-in-header{
  position: fixed;
  right: 60px;
  top: 8px;
  z-index: 1;
  color: #313133;
}

.header_drops {
  position: fixed;
  right: 20px;
  top: 0px;
  z-index: 10;
}
.header_drops{
  i{
    color: #313133;
    position: absolute;
    left: 15px;
    top: 12px;
    z-index: 1;
  }
}

.header_drops>button {
  background-color: transparent !important;
  border: solid 1px transparent !important;
  box-shadow: none !important;
}

.header_drops>button:hover {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

.header_drops>button:active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}

header {
  position: fixed;
  width: 100%;
  background: #fff;
  height: 40px;
  left: 0;
  top: 0;
  z-index: 100;
  color: #fff;
  transition: all 0.3s;
}


.cm_logo {
  position: absolute;
  width: 100px;
  top: 0;
  left: 0;
  transition: all 0.3s;
  background: transparent;
  padding: 5px 12px;
  height: 55px;
  display: flex;
  justify-content: center;
}

.cm_logo .headLogo {
  height: 30px;
}

img.sessionIcon {
  height: 166px;
}



.header_menu {
  position: absolute;
  right: 15px;
  top: 3px;
  cursor: pointer;
  width: 200px;
  text-align: right;
}

.admin_dropdown {
  display: none;
  position: absolute;
  width: 200px;
  right: 0;
  background: #282c34;
  top: 47px;
  border-radius: 0px 0px 5px 5px;
  padding: 10px 0;
  text-align: left;
}

.header_menu:hover .admin_dropdown {
  display: block;
}

.admin_img {
  width: 42px;
  height: 42px;
  display: inline-block;
}

.cm_angle_down {
  position: absolute;
  font-size: 24px;
  right: 49px;
  top: 11px;
}

.admin_dropdown ul li a,
.admin_dropdown ul li>span {
  display: block;
  padding: 8px 10px;
  color: #fff;
}

/* .cm_sidebar ul li a.active,
.cm_sidebar ul li > span:hover,
.cm_sidebar ul li a:hover, */
.admin_dropdown ul li>span:hover,
.admin_dropdown ul li a:hover {
  text-decoration: none;
  background: black;
  background: rgb(255, 108, 11);
  background: linear-gradient(45deg,
      rgba(255, 108, 11, 1) 0%,
      rgba(255, 12, 64, 1) 100%);
}

.cm_sidebar {
  position: fixed;
  width: 211px;
  height: 100vh;
  min-height: 500px;
  left: 0;
  top: 0;
  background: #323234;
  padding-top: 65px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  overflow-y: auto;
}



.menu_min_height {
  min-height: 5vh;
}

.cm_sidebar ul li a,
.cm_sidebar ul li>span {
  display: block;
  padding: 15px;
  color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  position: relative;
}

.show_sub_menu .child_menu {
  height: auto;
}

.sidebar_close .show_sub_menu .child_menu {
  height: 0 !important;
}

ul.child_menu>li>a {
  background: transparent !important;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
}


ul.header_sub_menu>li {
  padding: 0px 15px;
  position: relative;
  margin-right: 35px;
}

ul.header_sub_menu>li:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20px;
  background: transparent;
  bottom: -15px;
}

ul.header_sub_menu>li>span,
ul.header_sub_menu>li>a {
  display: block;
  color: #313133;
  cursor: pointer;
}

ul.header_sub_menu>li>a.active {
  color: #0d5a6c;
  font-weight: bold;
}



ul.header_sub_menu {
  display: flex;
  width: calc(100% - 290px);
  margin-left: 160px;
  padding: 10px;
}

ul.header_sub_menu>li>ul {
  position: absolute;
  background-color: #fff;
  border-radius: .25rem;
  left: 0;
  min-width: 200px;
  top: 26px;
  padding: 0px 0px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-26px);
  transition: all 0.3s;
}

ul.header_sub_menu>li:hover>ul {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

ul.header_sub_menu>li>ul>li {
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

ul.header_sub_menu>li>ul>li:last-child {
  border-bottom: 0;
}

ul.header_sub_menu>li>ul>li>span,
ul.header_sub_menu>li>ul>li>a {
  color: black;
  font-size: 14px;
  display: block;
  padding: 10px 20px;
  transition: none;
  cursor: pointer;
}

ul.header_sub_menu>li>ul>li>a.active,
ul.header_sub_menu>li>ul>li:hover>span,
ul.header_sub_menu>li>ul>li:hover>a {
  background: #0d5a6c;
  // background: linear-gradient(45deg, rgba(255, 108, 11, 1) 0%, rgba(255, 12, 64, 1) 100%);
  color: #fff;
}

.menu_drop_btn {
  color: #afafaf;
  font-size: 18px !important;
  font-weight: 600 !important;
  margin-inline: 6px;
  position: relative;
  top: 2px;
}

ul.header_sub_menu>li>ul>li>ul {
  position: absolute;
  right: -200px;
  background: #ececec;
  min-width: 200px;
  top: 0;
  padding: 0px 0px;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.7);
  border-radius: .25rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-26px);
  transition: all 0.3s;
}

ul.header_sub_menu>li>ul>li:hover>ul {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

ul.header_sub_menu>li>ul>li>ul>li {
  border-bottom: 1px solid #ebebeb;
  position: relative;
}

ul.header_sub_menu>li>ul>li>ul>li>a {
  color: black;
  font-size: 14px;
  display: block;
  padding: 10px 20px;
  transition: none;
  cursor: pointer;
}

ul.header_sub_menu>li>ul>li>ul>li>a.active,
ul.header_sub_menu>li>ul>li>ul>li:hover>a {
  background: #0d5a6c;
  // background: linear-gradient(45deg, rgba(255, 108, 11, 1) 0%, rgba(255, 12, 64, 1) 100%);
  color: #fff;
}

ul.header_sub_menu>li>ul>li:hover>.menu_drop_btn {
  color: #fff !important;
}

ul.header_sub_menu>li>ul .menu_drop_btn {
  right: 10px;
  top: 11px;
  position: absolute;
}


.body-container-wrapper {
  padding: 15px 10px;
  transition: all 0.3s;
  position: relative;
  padding-top: 46px;
}

.loader_wrapper svg {
  width: 100px;
}

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_wrapper .loader-inner-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
}

.sidebar_close .cm_sidebar ul li a,
.sidebar_close .cm_sidebar ul li>span {
  font-size: 0;
  text-align: right;
}

.sidebar_close .cm_sidebar ul li a i,
.sidebar_close .cm_sidebar ul li>span i {
  font-size: 20px;
}

.body-container {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  min-height: calc(100vh - 85px);
  position: relative;
}

.user_profile_img {
  width: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
}

.cm_profile_wrapper h5 {
  font-size: 16px;
  background: #efefef;
  word-break: break-all;
  min-height: 120px;
}

.cm_profile_wrapper h5:hover {
  background: #282c34;
  color: #fff;
}

.cm_profile_wrapper h5 b {
  color: #999999;
  display: inline-block;
  min-width: 135px;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}

.cm_profile_wrapper h5:hover b {
  color: #fff;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.cm_file_control_wrap {
  width: 150px;
  height: 150px;
  background: #e5e5e5;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

input.form-control.cm_file_control {
  position: absolute;
  width: 100%;
  height: 100%;
  background: red;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  z-index: 99;
}

.cm_file_control_box {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

span.cm_file_control_box i {
  position: absolute;
  font-size: 25px;
  right: 10px;
  bottom: 10px;
  color: #adadad;
}

.users_header {
  margin-bottom: 5px;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 9;
}

.form-group {
  position: relative;
}

.cm_error {
  position: absolute;
  color: red;
  font-size: 12px;
  font-weight: 500;
  bottom: -20px;
  left: 0;
}

.filter_text:hover {
  background: #efefef;
  cursor: pointer;
}

.pagination {
  justify-content: center;
}

.pagination li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}

.pagination li:not(.disabled):hover a,
.pagination .active a {
  background: #dee2e6;
  color: #000;
}

.pagination .disabled a {
  color: #bcbcbc;
}

.cm_inline {
  display: inline-block;
  padding-right: 15px;
  position: relative;
  padding-left: 9px;
}

.cm_hide_checkbox {
  opacity: 0;
  visibility: hidden;
}

.cm_inline label:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  background: transparent;
  opacity: 0;
  cursor: pointer;
}

.cm_checkbox_box {
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  left: 0;
  top: 1px;
  border: 1px solid #aba9a9;
}

.cm_checkbox_box:after {
  content: "";
  position: absolute;
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  background: transparent;
  border-radius: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.cm_hide_checkbox:checked+.cm_checkbox_box:after {
  background: #252525;
}

.cm_mt_5 {
  margin-top: 2.5rem;
}

.sm_msg {
  position: absolute;
  right: 15px;
  top: -16px;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  color: #bebebe;
}

.cm_edit_profile_wraper .cm_error {
  right: 15px;
}

.cm_align_bottom {
  margin-top: 65px;
}

.popup_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  z-index: 99999;
  transition: all 0.1s;
}

.popup_wrapper.cm_close {
  visibility: hidden;
  opacity: 0;
  transform: scale(2);
}

.popup_wrapper.cm_open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: all 0.3s;
}

.popup_inner_wrapper {
  max-width: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 100%;
  min-height: 400px;
  border-radius: 10px;
  padding: 40px 20px;
}

.close_popup {
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 10px;
  color: #777777;
  line-height: 1;
  cursor: pointer;
}

.close_popup:hover {
  color: #000;
}

.filter_text {
  position: relative;
}

.filter_text button {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

.filter_text {
  position: relative;
}

.filter_text ul li {
  font-size: 12px;
  font-weight: 400;
  padding: 5px 15px;
}

.filter_text ul li:hover {
  background: #000;
}

.filter_text ul {
  position: absolute;
  background: #282c34;
  color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  left: 0;
  top: 43px;
  transform: translateY(-25px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 99;
}

.filter_text.open ul {
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.filter_text ul:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #282c34;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.cm_ckeckbox_wrapper {
  position: relative;
  width: 45px;
  height: 15px;
  display: inline-block;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  margin: 0 15px;
  cursor: pointer;
}

.cm_ckeckbox_btn {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.38);
  display: inline-block;
  border-radius: 30px;
  left: 0;
  top: 0;
}

.cm_ckeckbox_btn:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: -2px;
  transition: all 0.3s;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #f1f1f1;
}

.cm_ckeckbox {
  position: absolute;
  right: 0;
  top: -6px;
  width: 60px;
  height: 30px;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
}

.cm_active .cm_ckeckbox_btn:after {
  left: 30px;
  background-color: #0ae340;
}

.cm_active .cm_ckeckbox_btn {
  background: #0bb302;
}

.radio_wrapper {
  background: #282c34;
  color: #fff;
  padding: 3px 15px;
  line-height: 1;
  border-radius: 5px;
  position: sticky;
  top: 0;
}

.radio_wrapper label input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 99;
}

.radio_wrapper label {
  position: relative;
  display: inline-block;
  margin-right: 30px;
  line-height: 40px;
  padding-right: 30px;
  overflow: hidden;
  margin-bottom: 0;
}

.radio_wrapper label:last-child {
  margin-right: 0px;
}

.radio_wrapper label:before,
.radio_wrapper label>span {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
}

.radio_wrapper label:before {
  width: 25px;
  height: 25px;
  right: 0;
  border: 3px solid #fff;
}

.radio_wrapper label>span {
  width: 13px;
  height: 13px;
  background: #fff;
  right: 6px;
  opacity: 0;
}

.radio_wrapper label input:checked+span {
  opacity: 1;
  background: #0d5a6c;
  // background: linear-gradient(45deg,
  //     rgba(255, 108, 11, 1) 0%,
  //     rgba(255, 12, 64, 1) 100%);
}

.cm_card {
  position: relative;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 40px 10px;
  padding-left: 100px;
  background: #117d97;
  color: #fff;
  min-height: 93px;
}
.firstbox-card{
 background: #0197ba;
}
.lastbox-card{
  background: #0d5a6c;
}


.cm_card:hover {
  background: #10414c;
}

.all_users {
  position: absolute;
  left: 19px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
}

.cm_card h3 {
  font-size: 16px;
  color: #fff;
}

.cm_card h2 {
  font-size: 29px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}

.cm_card.radio_btn_wrapper {
  padding-left: 10px !important;
}

.cm_fixed_btn {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 1px 10px;
  font-weight: 600;
  border-radius: 5px;
}

.cm_fixed_btn a {
  color: #fff;
}

.cm_fixed_btn a:hover {
  text-decoration: none;
}

.cm_alert_danger {
  color: red;
  position: absolute;
  left: 0;
  bottom: 39px;
  font-size: 12px;
}

.popup-overlay {
  z-index: 999 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.cm_dropdown ul {
  position: relative;
  padding: 0;
  background: #f5f2f2;
  border-radius: 5px 5px 5px 4px;
  overflow: hidden;
  height: 0px;
  transition: all 0.3s;
}

.cm_dropdown.open ul {
  height: 250px;
  overflow: scroll;
}

.cm_dropdown ul li {
  padding: 5px 15px;
  border-bottom: 1px solid gainsboro;
}

.cm_dropdown ul li h4 {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.cm_dropdown ul li p {
  margin-bottom: 10px;
}

.cm_dropdown ul li:last-child {
  border: 0;
}

.cm_dropdown ul li:hover {
  cursor: pointer;
  background: #e5e5e5;
}

.cm_dropdown.open .cm_blank_input {
  border-radius: 5px 5px 0 0;
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.cm_pos_realtive {
  position: relative;
  padding-right: 70px !important;
}

.accordion>.card {
  overflow: unset !important;
}

.cm_pos_realtive a,
.cm_pos_realtive .cm__btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
  padding: 2px 0px;
  font-size: 12px;
}

.cm_search i {
  position: absolute;
  left: 24px;
  top: 10px;
  color: #8c8c8c;
  font-size: 18px;
}

.cm_search input {
  padding-left: 30px;
}

.cm_page_heading {
  font-weight: bold;
  color: #0d5a6c;
}

.open_popup header,
.open_popup .cm_sidebar,
.open_popup .body-container-wrapper {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: 0;
}

.cm_show_pass i {
  position: absolute;
  top: 0;
  right: 0;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  cursor: pointer;
  font-size: 20px;
}

.cm_show_pass_inner i {
  top: 35px;
}

.user_profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

h3.empty_error {
  text-align: center;
  font-size: 17px;
  color: #ff6969;
  margin-bottom: 20px;
}

.empty_error span {
  font-weight: bold;
  border-bottom: 1px solid;
}

td {
  position: relative;
}

span.cm_btn_sm {
  position: absolute;
  font-size: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #0069d9;
  transition: all 0.3s;
}

.cm_btn_sm:hover {
  cursor: pointer;
  color: #00448e;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
}

.min_loader_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  background: white;
  width: 100%;
}

.min_loader_wrapper svg {
  width: 45px;
}

button.ladda-button {
  background: transparent;
  border: 0;
  outline: none;
  position: absolute;
  width: 100%;
  left: -10px;
  height: 100%;
  top: 0;
}

.ladda-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.ladda-spinner>div {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

form.edit_device_form {
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  padding: 50px;
  background: #efefef;
  border-radius: 5px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.m-b-md-15 {
  margin-bottom: 5px;
}

.consumed {
  position: absolute;
  right: 12px;
  top: 50%;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  transform: translateY(-50%);
}

.consumed.cm_yes {
  background: rgb(0, 255, 55);
  /* background: linear-gradient(45deg, rgba(255,108,11,1) 0%,rgba(255,12,64,1) 100%); */
}

.consumed.cm_no {
  background: rgb(253, 1, 1);
}

.send_coupon_email_wrapper {
  color: #868686;
}

.send_coupon_email_wrapper button {
  line-height: 1;
  padding: 5px 6px;
  font-size: 12px;
}

.cm_admin_links {
  position: absolute;
  bottom: 20px;
  background: #17191d;
  width: 100%;
  left: 0;
}

.all_users .treadmill_svg {
  width: 60px;
  fill: #fff;
  position: relative;
  top: -6px;
}

.all_users .coupons_svg {
  width: 54px;
  fill: #fff;
  position: relative;
  top: -6px;
}
.chart_wrapper{
  margin-top: 15px;
}
.chart_wrapper a:hover {
  text-decoration: none;
}

.table-responsive {
  padding-bottom: 20px;
}

.delete_coupon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 43px;
  cursor: pointer;
  color: #9a9a9a;
  padding: 5px 7px;
  line-height: 1;
  border-radius: 5px;
}

.delete_coupon:hover {
  background: #949494;
  color: #fff;
}

.cm_chart_wrapper {
  padding-left: 220px;
  padding-right: 15px;
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

.ant-picker-dropdown.upar_index {
  z-index: 10000;
}

.career-body .action-btn {
  min-width: 120px;
}

.career-body .cm_ckeckbox_wrapper {
  width: 28px;
  height: 9px;
  margin: 0 0px 0 15px;
}

.career-body .cm_ckeckbox_btn:after {
  width: 16px;
  height: 16px;
}

.career-body .cm_active .cm_ckeckbox_btn:after {
  left: 18px;
}

@media (max-width: 1290px) {
  ul.header_sub_menu>li {
    padding: 0px 10px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .sidebar_toggle {
    display: none;
  }
}

@media (max-width: 991px) {

  .cm_logo {
    width: 250px;
    text-align: center;
  }

  .sidebar_toggle {
    color: #0d5a6c;
    font-size: 24px;
    position: absolute;
    top: 6px;
    left: 270px;
    cursor: pointer;
  }

  .header_sub_menu {
    display: block !important;
    margin-left: 0 !important;
    position: fixed;
    left: 0;
    top: 55px;
    background: #282c34;
    width: 250px !important;
    height: 100vh;
    overflow: auto;
    transform: translateX(-250px);
    transition: all 0.25s;
  }

  .header_sub_menu>li {
    padding: 0 !important;
    margin-right: 0 !important;
  }

  .header_sub_menu>li>span,
  .header_sub_menu>li>a {
    color: #ffffff !important;
    padding: 15px 10px;
  }

  .menu_drop_btn {
    color: #afafaf;
    position: absolute;
    top: 17px;
    right: 0;
  }

  .header_sub_menu>li>ul {
    position: static !important;
    background-color: #efefef !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
    margin: 0 -16px;
    display: none;
    border-left: 10px solid #282c34;
  }

  .header_sub_menu>li.open_l_menu>ul {
    display: block !important;
  }


  .header_sub_menu>li>ul>li>ul {
    position: static !important;
    background: #ffffff !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
    transition: none !important;
    border-left: 18px solid #efefef;
    display: none;
  }

  ul.header_sub_menu>li>ul>li>span,
  ul.header_sub_menu>li>ul>li>a {
    padding: 15px 20px !important;
  }

  ul.header_sub_menu>li>ul>li,
  ul.header_sub_menu>li>ul>li>ul>li {
    border-bottom: 0 !important;
  }

  ul.header_sub_menu>li>ul .menu_drop_btn:before {
    content: "\F107" !important;
  }

  ul.header_sub_menu>li>ul .menu_drop_btn {
    top: 16px !important;
  }

  ul.header_sub_menu>li>ul>li:hover>span,
  ul.header_sub_menu>li>ul>li:hover>a {
    background: transparent !important;
    color: #000 !important;
  }

  ul.header_sub_menu>li>ul>li:hover>.menu_drop_btn {
    color: #afafaf !important;
  }


  .header_sub_menu>li>ul>li.open_l_menu_2>ul,
  .header_sub_menu>li.open_l_menu>ul {
    display: block !important;
  }

  ul.header_sub_menu>li>ul>li>a:hover {
    background: #0d5a6c !important;
    // background: linear-gradient(45deg, rgba(255, 108, 11, 1) 0%, rgba(255, 12, 64, 1) 100%) !important;
    color: #fff !important;
  }

  .sidebar_close .header_sub_menu {
    transform: translateX(0px);
  }


  .sidebar_close header {
    left: 0;
  }

  .cm_sidebar ul li a,
  .cm_sidebar ul li>span {
    font-size: 0;
    text-align: right;
  }

  .cm_sidebar ul li>span i,
  .cm_sidebar ul li a i {
    font-size: 20px;
  }



  .sidebar_close .cm_sidebar ul li>span,
  .sidebar_close .cm_sidebar ul li a {
    font-size: 14px;
    text-align: left;
  }

  .sidebar_close .cm_sidebar ul li>span i,
  .sidebar_close .cm_sidebar ul li a i {
    font-size: 14px;
  }

  .sidebar_close .cm_sidebar {
    left: 0;
  }



  .popup-content {
    width: calc(100% - 6px) !important;
  }

  .edit_device_form {
    max-width: 500px !important;
  }

  .cm_chart_wrapper {
    padding-left: 60px;
  }
}

@media (max-width: 767px) {

  .cm_logo{
    width: 100px;
    left: 60px;
  }
  .detail-dispay-in-header{
    width: 130px;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .sidebar_toggle{
    left: 20px;
    top: 0;
  }
  .body-container,
  .body-container-wrapper {
    padding: 15px;
  }
  .body-container-wrapper{
    padding-top:45px;
  }

  ol.breadcrumb {
    margin: 0;
  }

  .all_users {
    left: 15px;
    font-size: 28px;
  }

  .cm_card {
    padding-left: 60px;
    min-height: 80px;
  }

  .cm_profile_wrapper h5 {
    font-size: 14px;
    word-break: break-all;
  }

  .cm_profile_wrapper h5 b {
    display: block;
    margin-bottom: 5px;
  }

  .m-b-md-15 {
    margin-bottom: 15px;
  }

  .cm_modal {
    padding: 25px 15px !important;
  }

  .edit_device_form {
    padding: 30px 15px !important;
  }

  .all_users .coupons_svg,
  .all_users .treadmill_svg {
    width: 32px;
  }
}

@media (max-width: 479px) {
  .cm_fixed_btn {
    position: static;
    display: block;
    margin-bottom: 15px;
  }

  .mb-5 {
    margin-bottom: 1.5rem !important;
  }
}

@media (min-width: 1400px) {
  body {
    font-size: 16px;
  }
}

p.cm_question {
  color: red;
  font-style: italic;
}

.big_modal {
  font-size: 13px;
}

.cm_quition_list li {
  margin-bottom: 40px;
  padding: 20px 20px 20px 95px;
  box-shadow: 3px 3px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  list-style: none;
  min-height: 100px;
}

.cm_quition_list li .cm_ckeckbox_wrapper {
  position: absolute;
  left: 0;
  top: 23px;
}

.cm_quition_list li p {
  margin: 0;
  color: red;
  font-style: italic;
  font-weight: 600 !important;
}

.cm_options {
  color: #000 !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 14px;
  margin-bottom: 5px !important;
}

.cm_pointer {
  cursor: pointer;
}

.card_content img {
  height: 210px;
  object-fit: cover;
  width: 100%;
}

.object-contain {
  object-fit: contain !important;
}

.card_content h4 {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #282c34;
  margin-bottom: 20px;
}

.category_description {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  padding: 15px;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  background: rgba(0, 0, 0, 0.75);
}
.category_description h3{
  color: #fff;
}
.card_content:hover .category_description {
  opacity: 1;
  visibility: visible;
}

img.exercise_category_img {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.card_content {
  margin-bottom: 30px;
}

.card-img-top {
  height: 210px;
  background: #efefef;
  text-align: center;
  line-height: 210px;
  object-fit: cover;
}

.card_style_cat {
  position: absolute;
  padding: 3px 10px;
  background: #fffffff2;
  color: #000;
  font-size: 13px;
  border-radius: 4px;
  top: 8px;
  left: 8px;
  border: 1px solid #bbbbbb;
}

.exerciseImage {
  width: 50px;
  height: 31px;
  object-fit: cover;
}

.sm_image {
  width: 50px;
  height: 40px;
  object-fit: cover;
}

.cm_quition_list ol {
  margin: 0;
  padding: 0;
}

.cm_quition_list li {
  position: relative;
}

.cm_big_pop {
  max-width: 90% !important;
  width: calc(100% - 30px) !important;
}

.cm_big_pop .cm_modal {
  max-width: 100%;
}

.cm_table_three_dots {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  /* font-weight: 900 */
  /* font-weight: bold; */
}

.cm_no_wrap {
  white-space: nowrap;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 4px;
}

.three_dots {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-size: 18px;
  margin-bottom: 5px;
  &.width-80{
    width: 80%;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.dropzone {
  cursor: pointer;
  width: 100%;
  height: 20%;
  font-size: 57px;
  background-color: #eee;
}

.dropzone p {
  font-size: 25px;
}

.food_type_ {
  position: absolute;
  width: 22px;
  height: 22px;
  background: #fff;
  line-height: 1;
  right: 10px;
  top: 10px;
}

.cm_rating {
  position: absolute !important;
  left: 225px;
  top: 15px;
  cursor: pointer;
}

a.cm_rating {
  position: absolute;
  left: auto;
  right: 56px;
  font-size: 16px;
  color: #585858;
}

.more_padding {
  padding-right: 80px !important;
}

.ques_for_tag {
  position: absolute;
  padding: 3px 10px;
  background: #fffffff2;
  color: #000;
  font-size: 13px;
  border-radius: 4px;
  top: 30px;
  left: -7px;
  border: 1px solid #bbbbbb;
}

span.pull_center {
  line-height: 1;
  position: relative;
  top: -4px;
  font-size: 14px;
  font-weight: 600;
}

.about_food img {
  width: 100%;
  height: 156px;
  object-fit: cover;
}

.about_food {
  margin-bottom: 30px;
  box-shadow: 6px 6px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
}

.about_food p {
  font-size: 11px;
  font-weight: 600;
  line-height: 1;
}

.food_content_bg {
  // background: linear-gradient(45deg, #ff6c0b, #ff0c40);
  background: #0d5a6c;
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 11px;
  min-width: 306px;
  max-height: 105px;
}

.food_content_bg h4 {
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
}

.food_content_bg h6 {
  font-size: 13px;
  line-height: 1;
}

.diet_plan_wrapper {
  margin-left: 220px;
  margin-top: 83px;
}

.cm_patti {
  padding: 15px;
  background: #efefef;
  border-radius: 10px;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.16);
}

.cm_likesCount_wrap {
  position: relative;
  margin: 25px 0;
}

.cm_likesCount {
  position: absolute;
  right: 0;
  top: 0;
}

h4.cm_pos_rel {
  padding-right: 120px !important;
  background: #e9ecef;
  padding: 15px;
  border-radius: 5px;
  margin-top: 30px;
  box-shadow: 3px 3px 4px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}

h4.cm_pos_rel span.btn {
  position: absolute;
  right: 11px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

h4.cm_pos_rel span.btn.btn-info {
  background: #fff;
  color: #000;
  border-color: #fff;
}

h4.cm_pos_rel.cm_orange {
  background: #ff5816;
  color: #fff;
}

.cm_abs {
  position: absolute;
  right: -7px;
  bottom: 16px;
  cursor: pointer;
}

.form_group_wrapper .form-group {
  margin-bottom: 20px;
}

.form_group_wrapper {
  margin-bottom: 20px;
  padding: 20px;
}

.form_group_wrapper h4 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #434349;
  margin-bottom: 15px;
}

.cm_big_modal {
  max-width: 75%;
}

.__btn_abs {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 100px;
}

.cm_slots_div {
  max-width: 220px;
}

.cm_slots {
  font-size: 11px;
  min-width: 68px;
}

span.widget_label {
  color: #48465b;
  font-weight: 600;
}

.cm_dropdown {
  display: inline-block;
}

.cm_dropdown .dropdown-menu {
  transform: none !important;
  right: 0;
  left: auto !important;
  top: 37px !important;
}

.cm_trackingId {
  width: 165px;
}

.align_date {
  padding-top: 13px;
  padding-bottom: 13px;
}

.tracks_img {
  max-width: 16%;
  max-height: 200px;
  object-fit: cover;
  margin: 10px;
  margin-bottom: 20px;
}

.cm__btn {
  min-width: 38px;
}

.btn_sm {
  min-width: 54px;
}

.product_ques_tag {
  position: relative;
  padding: 3px 10px;
  background: #fffffff2;
  color: #000;
  font-size: 13px;
  border-radius: 4px;
  left: -94px;
  border: 1px solid #000000;
}

.ant-switch-checked {
  background-color: #08a53f !important;
}

.cm_select_filter {
  min-width: 124px;
}

.cm_table_height {
  min-height: 450px;
}

.ql-editor {
  min-height: 450px;
}

/* Dashboard starttttttttttttttttttt */

.dash_body_container {
  min-height: 100vh;
  position: relative;
  padding: 66px 3.5rem 29px calc(35px + 2.2rem);
  background: #f9fbfd;
}

.dash_header {
  padding: 1.3rem 2.2rem;
  width: calc(100% - 260px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  background: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(44%, hsla(0, 0%, 97.3%, 0.95)),
      color-stop(73%, hsla(0, 0%, 97.3%, 0.46)),
      to(hsla(0, 0%, 100%, 0)));
}

.sidebar {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 260px;
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  z-index: 999;
  background: #000;
  padding-top: 81px;
}

.sidebar ul {
  padding-inline-start: 0px;
}

.sidebar>ul>li>a {
  padding: 10px 15px 10px 25px;
  display: block;
  position: relative;
  color: #fff;
  font-family: "CeraproMd";
}

.sidebar>ul>li>a>span svg {
  width: 19px;
  height: 19px;
  margin-right: 20px;
}

.sidebar>ul>li>a.active,
.sidebar>ul>li>a:hover {
  background: #666666;
  color: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #fff;
  text-decoration: none;
}

.tab-group {
  // font-family: "CeraPro";
  font-size: 14px;
  margin-top: 12px;
}

.tab-group .heading h5 {
  font-size: 14px;
  // font-family: "CeraProMd";
}

.graphparent-card {
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  margin-top: 35px;
}

.graphparent-card {
  /* width: 76%; */
}

.text-top-bottom {
  font-family: "CeraProBold";
  font-size: 18px;
}

.inner-graph-card {
  max-height: 400px;
}

.piegraph-parent-card {
  /* width: calc(100% - 76%); */
  /* margin-left: 2%; */
}

.next-prev-text i {
  color: #fdca26;
}

.next-prev-text {
  font-family: "CeraProBold";
  text-transform: capitalize;
  margin: 14px 0 26px 0;
}

.heading_backarrow {
  position: relative;
  margin: 10px 0 34px;
  font-weight: 600;
}

.backarrow svg {
  width: 20px;
  margin-right: 8px;
}

.heading_backarrow span {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0px, -50%);
  text-transform: capitalize;
}

.heading_backarrow span svg,
.heading_backarrow svg {
  width: 20px;
  margin-right: 8px;
}

.heading_backarrow span svg path,
.heading_backarrow svg path,
.backarrow svg path {
  fill: #000;
}

.heading_backarrow {
  margin: 10px 0 0px;
  font-family: "FuturaMd";
  font-size: 20px;
  font-weight: 300 !important;
  margin-bottom: -16px;
  cursor: pointer;
}

.cm_tab a,
.cm_tab a:hover {
  padding: 0 11px;
  color: #000;
  display: inline-block;
}

.blogview .cm_tab a,
.blogview .cm_tab a:hover {
  margin-top: 20px;
}

.cm_tab a:hover {
  text-decoration: none;
}

.cm_tab a.active {
  font-family: "CeraProMd";
  position: relative;
}

.cm_tab a.active:after {
  width: 76%;
  height: 2px;
  background: #fdca26;
  content: "";
  position: absolute;
  left: 12%;
  bottom: -6px;
}

.graph {
  margin: 20px 30px;
}

.divider-light-line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #cccccc85, #fafafa);
}

/* ------------------------------ant progress bar--------- */

.postion-rel {
  position: relative !important;
}

.progress-section .ant-progress-circle-trail,
.progress-section path.ant-progress-circle-path {
  stroke-width: 3px;
}

.progress-section span,
.circle .center-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
}

.progress-section .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #000;
}

.progress-section span.ant-progress-text,
span.ant-progress-text {
  display: none;
}

.heart .ant-progress-show-info .ant-progress-outer,
.sleep-progress .ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  padding-right: 0;
}

.progress-section span svg,
.circle .center-section svg,
.circle .center-section svg path,
.progress-section span svg path {
  width: 44px;
}

.progress-section span svg path {
  fill: #000 !important;
}

.circle .center-section svg path {
  fill: #fff;
}

.next-prev-text {
  font-family: "CeraProBold";
  text-transform: capitalize;
  margin: 14px 0 26px 0;
}

.progress-section p small,
.stepinfo p small,
.bottom-details p small,
.heart p small,
.calorie-info p small {
  // font-family: "CeraProMd";
  font-size: 14px;
}

.progress-section p,
.stepinfo p,
.bottom-details p,
.heart p,
.calorie-info p {
  // font-family: "CeraProBold";
  font-size: 14px;
  margin: 8px 0;
}


.circle .center-section p,
.circle .center-section {
  font-size: 24px;
  font-family: "FuturaMd";
  color: #fff;
  flex-wrap: wrap;
}

.Graph_Icons {
  color: black;
}

.Graph_Icons svg {
  width: 25px;
}

.Graph_Icons svg path {
  fill: black;
}

.cm_graphs_sidebar {
  height: 100%;
  margin-left: -24px !important;
}

.ant-tabs-nav-list {
  /* padding-right: 80px; */
}

.align-items-center {
  align-items: center;
}

/********** Steps- Graph************** */
/* ----------step dashboard---------- */
.stepinfo span svg,
.stepinfo span svg path {
  width: 20px;
  fill: #000;
}

.stepinfo p,
.bottom-details p,
.heart p,
.weight-info p,
.sleep_info p {
  margin: 0;
}

.overall-activty span {
  font-family: "CeraProMd";
  font-size: 15px;
}

.bottom-details span {
  font-family: "CeraProMd";
  font-size: 15px;
  width: 16%;
}

.cm_margin_top {
  margin-top: 85px;
}

/****************Steps graph end************8 */

/* ********************Heart rate graph**********************8 */
.heart span {
  font-family: "CeraProMd";
  font-size: 13px;
}

.heart {
  margin: 0 10px;
}

.heart .ant-progress-bg {
  height: 5px !important;
}

.heart .ant-progress {
  line-height: 0 !important;
}

.heart.peak .ant-progress-inner {
  background: #ffcbcb;
}

.heart.peak .ant-progress-bg {
  background: #ff0000;
}

.heart.cardio .ant-progress-inner {
  background: #d1f4f6;
}

.heart.cardio .ant-progress-bg {
  background: #1bc8d0;
}

.heart.Fatburn .ant-progress-inner {
  background: #ffe0cf;
}

.heart.Fatburn .ant-progress-bg {
  background: #ff670f;
}

/* *****************************Heart rate graph end**************************8 */

/* *********************Calories graph**************************8 */
.calorie-info span svg,
.weight-info span svg {
  width: 20px;
  margin-right: 10px;
}

.calorie-info span.burn svg path,
.weight-info span.starting svg path,
.calorie-info span.awake svg path {
  fill: #ff670f;
}

.calorie-info span.intake svg path,
.weight-info span.target svg path,
.calorie-info span.light svg path {
  fill: #1bc8d0;
}

.weight-info span.initial svg path,
.calorie-info span.rem svg path {
  fill: #ff0000;
}

.calorie-info span.deep svg path {
  fill: #4a43d1;
}

.calorie-info,
.weight-info,
.sleep-info {
  margin: 0 12px;
}

/* ***************************Calories graph end*******************8 */

/* *********************Weight graph******************/
.weight-table .table-bordered td:last-child,
.table-bordered th:last-child {
  border-right: none;
}

.weight-table .table-bordered td:first-child,
.table-bordered th:first-child {
  border-left: none;
}

.weight-table .table-responsive {
  border: 1px solid #dee2e6 !important;
  border-radius: 10px;
  border-collapse: collapse;
  font-size: 15px;
  margin: 40px 0;
}

.weight-table table th,
.weight-table table tbody tr td:first-child {
  font-family: "CeraProMd";
}

.weight-table table {
  margin-bottom: 0;
}

.weight-table table tbody tr:nth-child(odd) td {
  background: #fff;
}

.weight-table table tbody tr td:first-child,
.weight-table table thead tr th:first-child {
  background: #000 !important;
  color: #fff;
  border: none;
}

.weight-table table tbody tr td {
  font-family: "CeraProLight";
}

/* *********************Weight graph end***************** */

.cm_btn_width {
  min-width: 175px !important;
}

.cm_accept {
  font-size: 22px !important;
  color: green;
  cursor: pointer;
}

.cm_reject {
  font-size: 22px !important;
  color: red;
  cursor: pointer;
}

.exercise_card {
  margin-bottom: 30px;
  box-shadow: 6px 6px 12px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: relative;
}

.exercise_card img {
  height: 180px;
  width: 100%;
  object-fit: cover;
}

.cm_exrsc_cat {
  font-size: 11px;
}

.exercise_content {
  position: relative;
  padding: 10px;
  margin-top: 10px;
  min-height: 142px;
}

.exercise_content_dec {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100%;
  color: #fff;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
}

.exercise_card:hover .exercise_content_dec {
  opacity: 1;
  visibility: visible;
}

.exercise_content h3 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #434349;
}

.cm_youtobe {
  position: absolute;
  right: 13px;
  top: 8px;
}

.cm_days>span {
  display: block;
  color: #000;
  background: #fff;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 4px;
  margin-right: 5px;
  width: 120px;
  margin-top: 8px;
}

/** Profile Page */
.cm_left_tabs {
  display: inline-block;
  width: 200px;
  margin-left: -25px;
  background: #ffffff;
  height: calc(100vh - 254px);
  box-shadow: 5px 5px 25px 0 rgba(82, 63, 105, 0.05);
}

.cm_right_tabs {
  display: inline-block;
  width: calc(100% - 200px);
  padding: 10px 25px;
  vertical-align: text-top;
}

ul.questions_list {
  margin: 0;
  padding: 0;
  list-style: none;
}

p.cm_questions {
  font-style: italic;
  color: red;
}

p.cm_answers {
  color: black;
  font-weight: 600;
  padding-left: 20px;
}

/*Chat page*/

.chat_wrapper {
  padding: 0 10px;
  padding-top: 25px;
}

.chat_massage_header {
  padding: 0 25px;
  border-bottom: 1px solid #ebedf2;
  margin: 0 -25px;
  min-height: 60px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.chat_massage__title {
  font-size: 1.1rem;
  color: #48465b;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.chat_massage__status {
  color: #74788d;
  font-size: 0.9rem;
  font-weight: 500;
  display: block;
  text-align: left;
}

.chat_user_dot {
  display: inline-block;
  line-height: 6px;
  min-height: 6px;
  min-width: 6px;
  height: 6px;
  width: 6px;
  font-size: 0;
  vertical-align: middle;
  background: #1dc9b7;
  border-radius: 100%;
}

.chat_massage_bottom {
  padding: 25px;
  border-top: 1px solid #ebedf2;
  margin: 0 -25px;
}

.cm_textarea textarea {
  width: 100%;
  border: 0 !important;
  outline: 0 !important;
  background-color: transparent !important;
  color: #000000;
  height: 50px;
}

.cm_msg_send_wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.chat_tool {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #eaecf2;
  margin-right: 12px;
  text-align: center;
  line-height: 30px;
  color: #a0a0a0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s;
}

span.chat_tool:hover {
  background: #1dc9b7;
  color: #fff;
}

.msg_send_wrapper button {
  background: transparent;
  border: 0;
  color: #1dc9b7;
  font-size: 20px;
  cursor: pointer;
  outline: none;
}

.msg_send_wrapper button:hover {
  background: #1dc9b7;
  color: #fff;
}

.msg_send_wrapper button:focus {
  outline: none;
}

.chat_massage_body {
  min-height: calc(100vh - 404px);
  max-height: calc(100vh - 50px);
  overflow: auto;
  margin: 0 -15px;
  padding: 10px;
  position: relative;
}

.chat_timeline {
  display: flex;
  align-items: center;
}

.timeline_img {
  height: 27px;
  width: 27px;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.timeline_name {
  padding: 0 0.4rem;
  font-size: 0.9rem;
  color: #48465b;
  font-weight: 500;
  width: calc(100% - 27px);
}

.timeline_datetime {
  color: #74788d;
  font-size: 0.9rem;
  font-weight: 400;
}

.chat__text {
  padding: 0.5rem;
  margin-top: 0.8rem;
  color: #6c7293;
  font-weight: 500;
  font-size: 1.1rem;
  border-radius: 4px;
  background-color: rgba(29, 201, 183, 0.1) !important;
  min-width: 150px;
  display: inline-block;
  word-wrap: break-word;
  position: relative;
}

.my_chat .chat_timeline {
  flex-direction: row-reverse;
}

.my_chat {
  text-align: right;
}

.chat_wrap {
  margin: 20px 0;
}

@media (max-width: 1400px) {
  .chat__text {
    max-width: 60%;
    min-width: 150px;
  }
}



span._msg_time {
  font-size: 10px;
  display: block;
  margin-top: 10px;
  text-align: right;
}

.ditician-dropdown {
  width: 158px;
}

.ditician-dropdown div {
  text-align: left;
}

.cm_min_width {
  min-width: 160px;
}

.cm_error_row {
  background-color: red !important;
}

.modal-dialog.cm_modal_hw {
  min-width: 85%;
}

.cm_modal_hw .modal-body {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

.min_height_new .css-4ljt47-MenuList {
  max-height: 200px;
}

.min_height_new{
  &.read-only{
    .custom__control{
      border-width: 0;
      .custom__value-container{
        justify-content: center;
        color: #000000;
      }
    }
    .custom__indicators{
      display: none;
    }
  }
}

.cm_ckeckbox_wrapper_height {
  min-height: 23px !important;
}

.cm_min_filter_width {
  min-width: 175px !important;
}

.cm_min_width_address {
  min-width: 23rem;
}

.serch_progress .progress {
  position: absolute;
  width: calc(100% - 30px);
  left: 16px;
  top: -16px;
}

.addvender-form-content {
  max-width: 100% !important;
  width: 100% !important;
  height: 100%;
}

.addvender-form-content .cm_modal {
  max-width: initial;
  height: 100%;
  overflow-y: auto;
}

.addvender-form-overlay {
  padding: 0;
}

.addvender-form-content .cm_modal_close {
  top: 10px;
  right: 0;
}

.addvender-form-content .cm_modal.form-group {
  max-width: 1000px;
  margin: 0 auto;
}

.addvender-form-content .cm_modal .form-group,
.addvender-form-content .cm_modal button.btn-block {
  max-width: 1200px;
  margin: 0 auto;
}

/* .addvender-form-content .ReactVirtualized__Grid {
  margin: 0 auto;
  width: 100% !important;
  max-width: 1200px;
}

.addvender-form-content
  .ReactVirtualized__Grid
  .ReactVirtualized__Grid__innerScrollContainer {
  max-width: 100% !important;
  font-size: 19px;
  overflow-y: auto !important;
  height: 100% !important;
  max-height: 300px !important;
}

.addvender-form-content
  .ReactVirtualized__Grid
  .ReactVirtualized__Grid__innerScrollContainer
  > div {
  height: auto !important;
  padding: 4px 0;
} */
.cm-state-container {
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: row-reverse;
}

.state-wrapper svg {
  width: 50px;
  height: 30px;
  float: right;
  cursor: pointer;
}

.cm-state1 button.close {
  padding: 0 10px;
}

.wrapper-overflow {
  max-height: 300px;
  overflow-y: auto;
}

.engineer-pincodes {
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
}

.send_otp {
  color: red;
  cursor: pointer;
}

.date_time {
  font-size: 12px;
}

.color_input {
  width: 234px;
}

.right_spacing {
  margin-right: 12px !important;
}

.right_spacing2 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.right_spacing3 {
  margin-right: 12px !important;
}

.birthday_icon {
  height: 25px;
  width: 25px;
}

.transform {
  text-transform: uppercase;
}

.list {
  padding: 6px;
}

.imput-box {
  margin-left: 10px;
}

.clear-filters {
  /* margin-left: 148px; */
  font-size: 22px;
  margin-right: 10px;
}

.breadcrumb-custom {
  border: none !important;
  margin: 0px !important;
  padding: 5px 0 !important;
}

.breadcrumb-custom .breadcrumb-item a {
  color: #0d5a6c;
  font-size: 14px;
}

.timer {
  font-size: 16px;
  font-weight: bold;
  color: #0d5a6c;
}

.padding-class {
  padding-top: 53px !important;
}

.display-bold {
  /* font-weight: bold !important;
  font-size: 16px !important; */
  font-weight: bold !important;
  font-size: 17px !important;
}

mark {
  background-color: white !important;
  color: #0000EE;
}

@media screen and (max-width:1024px) {
  .timer {
    // font-size: 22px;
    text-align: right;
  }

  .clear-filters {
    font-size: 18px;
  }

  .custom-list {
    margin-top: 0px
  }

  .custom-table td,
  .custom-table th {
    padding: .5rem .5rem;
    font-size: 16px;
  }

}

/* before/after img/vid  */
.sidereel {
  max-width: 100px;
  max-height: 600px;
  overflow-y: auto;
}

.sidereel img {
  max-width: 100%;
  height: 100px;
  width: 100%;
  object-fit: cover;
  border: 2px solid #fff;
  background: #eee;
  cursor: pointer;
}

.fullimgslider img {
  width: 100%;
  max-width: 500px;
  display: block;
  margin: 0 auto;
}

.img_gallery {
  display: flex;
  padding: 20px 0;
  max-width: 700px;
  margin: 0 auto;
}

.fullimgslider {
  width: 100%;
  padding: 10px;
}

.videoreel {
  position: relative;
  margin: 5px;
  display: flex;
}

.videoreel i.fa.fa-video-camera {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.customVideoreel{
  margin: 5px 0;
}

.customVideoreel video{
  max-width: 100%;
  height: 100px;
  width: 100%;
  object-fit: cover;
  border: 2px solid #fff;
  background: #eee;
  cursor: pointer;
}

.imagereel.position-relative {
  i.fa {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
  }
}

.fullimgslider video {
  width: 100%;
  max-height: 400px;
  background-size: cover;
  object-fit: scale-down;
}

video.cm_video {
  width: 460px;
  /* height: 400px; */
  display: block;
  margin: 0 auto 20px;
}

.rbc-timeslot-group .rbc-time-slot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-timeslot-group .rbc-time-slot:last-child {
  display: none;
}

.rbc-timeslot-group {
  min-height: 120px !important;
}

.rbc-day-slot .rbc-event {
  float: left !important;
  width: 100% !important;
  position: initial !important;
  border: 1px solid #d9cece !important;
}

.rbc-day-slot .rbc-event-label {
  float: left !important;
  width: 100% !important;
}

.rbc-day-slot .rbc-event-content {
  float: left !important;
  width: 100% !important;
}

.rbc-day-slot .rbc-event-content {
  float: left !important;
  width: 100% !important;
  min-height: 0 !important;
}

.partia_space_5 .rbc-timeslot-group {
  min-height: 241px !important;
}

/* .partia_space_5 .rbc-events-container .rbc-event{
  height: 0.276963% !important;
} */

.partia_space_10 .rbc-timeslot-group {
  min-height: 120px !important;
}

/* .partia_space_10 .rbc-events-container .rbc-event{
  height: 0.262963% !important;
} */

.partia_space_20 .rbc-timeslot-group {
  min-height: 60px !important;
}

.partia_space_20 .rbc-events-container .rbc-event {
  height: 0.262963% !important;
}

.partia_space_30 .rbc-timeslot-group {
  min-height: 60px !important;
}


.hover {
  cursor: pointer;
  height: 33px;
}

.btn-block1 {
  display: block;
  width: 70%;
}

.width_trainer_image {
  max-width: 345px;
  width: 100%;
}

.cm_del {
  position: absolute;
  right: 19px;
}

.modal-lg-engineers {
  max-width: 70%;
}

.pincode-height-max {
  max-height: 200px;
  overflow: scroll;
}

/* modal style  */
.ourdoormodal-content {
  max-width: 960px;
  width: 100% !important;
}

.ourdoormodal-content .cm_modal {
  max-width: 100%;
}

/* Streaming style */
@import './assets/streaming.scss';

/* users module */
@import './assets/userModule.scss';

/* emoji */
@import './assets/emoji.scss';

// fitness
@import './assets/fitness.scss';

// common style
@import './assets/styles/common.scss';

// commercial services
@import './assets/styles/commercialServices.scss';

.msg_card {
  border: 1px solid #d6d6d6;
  margin-bottom: 16px;
  padding: 15px;
  border-radius: 4px;
  position: relative;
  padding-right: 130px;
  min-height: 80px;
  background: #f9f9f9;
}

.msg_card time {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #5f5f5f;
}

.msg_card .msg_status {
  position: absolute;
  right: 10px;
  top: 35px;
  background: #389400;
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  min-width: 88px;
  text-align: center;
}

.msg_card .msg_status.rejected {
  background: red;
}

.msg_card p {
  margin: 0;
}

.cm__search_box {
  position: relative;
}

.cm__search_box i {
  position: absolute;
  left: 15px;
  top: 12px;
  color: #6c757d;
}

.cm__search_box input {
  padding-left: 40px;
  min-width: 350px;
}

.cm_actions i {
  cursor: pointer;
  margin: 0 10px;
  font-size: 18px;
  color: #afafaf;
}

.cm_actions i:hover {
  color: #000;
}

.cm_import_file {
  position: relative;
}

.cm_import_file input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  font-size: 0;
  cursor: pointer;
  z-index: 9;
  opacity: 0;
}

.SrNo {
  width: 100px;
}

.disable-cursor {
  cursor: not-allowed;
  opacity: 0.3;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  padding-left: 20px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}

.ck.ck-content ol {
  padding-left: 20px;
}
.custom-control-label::before, .custom-control-label::after{
  cursor: pointer;
}

.gymEquipmentSelect div :nth-child(1) {
  max-height: 100px !important;
  overflow-y: auto;
}
