.stream-detail-header .filter-and-details {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  width: 60%;
}

.stream-detail-header .filter-and-details .filter-wrapper {
  width: 100%;
  display: flex;
}

.stream-detail-header .filter-and-details .clear-filter {
  width: 40px;
  width: 40px;
  font-size: 28px;
  margin-top: 5px;
  position: relative;
  color: #dedede;
  cursor: not-allowed;

  &.active {
    color: #000000;
    cursor: pointer;
  }
}

.stream-detail-header .filter-and-details .filters {
  width: calc(100% - 40px);
  display: flex;
}

.stream-detail-header .filter-and-details .filters .filter-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  .active-inactive-user{
    // background-color: red;
    .ant-switch-inner{
      display: flex;
    }
  }
}

.stream-detail-header .filter-and-details .filters .custom-filter-select,
.stream-detail-header .filter-and-details .filters .birthday-filter,
.stream-detail-header .filter-and-details .filters .download-user-list {
  width: 155px;
  margin-right: 10px;
  font-size: 14px;
}
.stream-detail-header .filter-and-details .filters .custom-filter-select{
  .custom-filter-select__menu{
    z-index: 9;
  }
}

.stream-detail-header .filter-and-details .filters .birthday-filter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stream-detail-header .filter-and-details .filters .birthday-filter .label {
  margin-bottom: 2px;
  margin-left: 10px;
}


.stream-detail-header .filter-and-details .filters .download-user-list .button-primary,
.downloads-button-wrapper .button-primary {
  background-color: #1E7E34;
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}

.downloads-button-wrapper .btn-primary {
  font-size: 12px;
}

.downloads-button-wrapper .btn {
  width: 150px;
}

.stream-detail-header .filter-and-details .stream-detail {
  display: flex;
  flex-wrap: wrap;
}

.stream-detail-header .filter-and-details .stream-detail ul.detail-list {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.stream-detail-header .filter-and-details .stream-detail ul.detail-list li {
  margin-bottom: 5px;
  border-radius: 25px;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 4px 10px;
  background-color: #dedede;

  &.customer-count {
    background-color: #0d5a6c;
    color: #ffffff;

    .value-count {
      background: #ffffff;
      color: #000000;
      padding: 0 4px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.stream-detail-header .filter-and-details .stream-detail ul.detail-list li .value {
  font-weight: bold;
  margin-left: 5px;
}

.stream-detail-header .filter-and-details .stream-detail ul.detail-list li .value.star {
  .star-ratings {
    .star-container {
      margin-top: -6px;
    }
  }
}

.stream-detail-header .filter-and-details .clear-filter .remove-filter {
  width: 26px;
  height: 30px;
}

.remove-filter {
  cursor: pointer;
}

/* table */
.custom-table-view {
  th {
    font-weight: 500;
    &.active{
      background-color: #dedede;
      // color: #ffffff;
    }
  }
  .inactive{
    background-color: #dedede;
    // color: #ffffff;
    td{
      color: #605f5f;
    }
  }
}

.custom-table-view.stream-detail td {
  font-weight: 700;
}

.custom-table-view td,
.custom-table-view th {
  padding: .5rem .5rem;
  font-size: 14px;
  vertical-align: middle;
}

// rangepicker popup
// .time-range-popup{
//   position: fixed !important;
//   top: 150px !important;
//   left: 50% !important;
//   transform: translateX(-50%) !important;
//   .ant-picker-range-wrapper{
//     min-width: auto !important;
//   }
// }

/* connected icons */
.icons {
  width: 20px;
  height: 20px;
}

.icons-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* .table-responsive */
.table-responsive {
  white-space: nowrap;
}

/* streaming page  */
.user-header-streaming {
  display: flex;
  justify-content: space-between;
}

.streaming-header-wrapper {
  width: 70%;
  margin-right: 0;
  // width: calc(70% - 40px);
  // margin-right: 40px;
  display: flex;
  align-items: center;
}

.streaming-header-wrapper .streaming-filter {
  width: 40px;
  width: 40px;
  font-size: 28px;
  margin-top: 5px;
  position: relative;
  color: #dedede;
  cursor: not-allowed;

  &.active {
    color: #000000;
    cursor: pointer;
  }
}

.streaming-header-wrapper .streaming-filter .remove-filter {
  width: 26px;
  height: 30px;
}

.streaming-header-wrapper .filter-wrappers {
  width: calc(100% - 40px);
  display: flex;
  flex-wrap: wrap;
}

.streaming-header-wrapper .filter-wrappers .filter-select-wrapper {
  width: 20%;
  padding-right: 20px;
  .form-control{
    font-size: 13px;
    .ant-picker-input{
      input{
        font-size: 13px;
      }
    }
  }
}

.user-header-streaming .downloads-button-wrapper {
  width: auto;
  // width: 30%;
  margin-left: auto;
}

.stream-action-icon {
  font-size: 20px !important;
  width: 20px;
}

.emojis-message {
  margin-bottom: auto;
  margin-top: 10px;
  width: 40%;
  .single-msg {
    margin-bottom: 1px;
    margin-right: 0px;
    border-bottom: 1px solid #dedede;
    padding: 3px;

    .time,
    .sender,
    .send-mgs {
      color: #4c4c4c;
      margin-right: 10px;
      font-size: 12px;
    }
    .send-mgs {
      max-width: 100px;
      height: 20px;

      .emoji {
        max-width: 100%;
        max-height: 100%;
        min-width: 20px;
      }
    }

    .receiver {
      font-size: 12px;
    }
  }
}

.add-emoji {
  background-color: #0d5a6c;
  ;
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;

  .emoji-add-icon {
    width: 14px;
    height: 14px;
  }
}

.individual-emoji {
  position: relative;

  .add-emoji {
    border-radius: 50%;
  }

  .count {
    background-color: #0d5a6c;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 15px;
    font-size: 12px;
    cursor: pointer;
    &.inactive{
      background-color: #dedede;
      color: #000000;
    }
  }
}

.send-emoji-popup {
  border: 2px solid #FF6C0B;
  padding-bottom: 0 !important;
  border-radius: 8px;
  overflow: hidden;

  .list-emojis {
    height: 60vh;
    overflow: auto;
    .emoji-icon {
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;

      img {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
      }
    }
  }

  .list-messages {
    max-height: 70vh;
    overflow: auto;

    .message {
      border-bottom: 1px solid #dedede;
    }
  }

  .emojis-list {
    height: 70vh;
    overflow: auto;
    padding-right: 20px !important;
    .emoji-item {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #dedede;

      .emoji-image-individaul {
        height: 30px;

        img {
          height: 100%;
        }
      }

      .emoji-count {
        border-radius: 15px;
        background-color: #0d5a6c;
        color: #ffffff;
        font-size: 14px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        max-height: 25px;
      }
    }
  }
}

.custom-carousel-width {
  margin-left: 25px;
  margin-bottom: 10px;
}

.icon-finish {
  width: 40px;
}

.stream-detail-header .filter-and-details .filters .custom-filter-select,.stream-detail-header .filter-and-details .filters .birthday-filter, .stream-detail-header .filter-and-details .filters .download-user-list{
  width: 130px;
}

.custom-pop-design{
  width: 50%;
}

@media(max-width: 1025px) {

  .stream-detail-header .filter-and-details .filters .custom-filter-select,
  .stream-detail-header .filter-and-details .filters .birthday-filter,
  .stream-detail-header .filter-and-details .filters .download-user-list,
  .stream-detail-header .filter-and-details .stream-detail ul.detail-list,
  .custom-table-view td,
  .custom-table-view th {
    font-size: 12px;
  }

  .custom-table-view th {
    white-space: nowrap;
  }

  .streaming-header-wrapper .filter-wrappers .filter-select-wrapper {
    width: 50%;
    padding-right: 20px;
  }

  .stream-detail-header .filter-and-details .filters .custom-filter-select,
  .stream-detail-header .filter-and-details .filters .birthday-filter,
  .stream-detail-header .filter-and-details .filters .download-user-list {
    width: 120px;
  }
}

// exit session popup
.exist-session {
  .msg {
    font-size: 18px;
  }
}

@media(max-width: 767px) {

  .stream-detail-header{
    .filter-and-details {
      width: 100%;
      .filter-wrapper {
        width: 100%;
      }
      .stream-detail {
        width: 100%;
        margin-left: 0;
        ul.detail-list {
          justify-content: flex-start;
        }
      }
    }
  } 

  .emojis-message{
    width: 100%;
  }

  .text-filter-mobile{
    font-size: 12px;
  }

  /* streaming page */
  .streaming-header-wrapper .filter-wrappers .filter-select-wrapper,
  .user-header-streaming .downloads-button-wrapper {
    width: 100%;
  }

  .streaming-header-wrapper {
    width: 100%;
    margin-right: 0;
    display: block;
  }

  .streaming-header-wrapper .filter-wrappers {
    width: 100%;
  }

  .streaming-header-wrapper .filter-wrappers .filter-select-wrapper {
    padding-right: 0;
  }

  .user-header-streaming {
    flex-wrap: wrap;
    flex-direction: column-reverse;

    .downloads-button-wrapper {
      .add-session {
        .btn {
          width: 100%;
        }
      }
    }
  }

  .streaming-header-wrapper .streaming-filter {
    width: auto;
  }
}
.stream-pad{
  padding: 0 15px 15px;
}
@media(max-width: 575px) {
  .stream-detail-header .filter-and-details .clear-filter {
    width: auto;
    margin-bottom: 15px;
  }

  .stream-detail-header .filter-and-details .filter-wrapper {
    flex-wrap: wrap;
  }

  .stream-detail-header .filter-and-details .filters {
    width: 100%;
    flex-wrap: wrap;
  }

  .stream-detail-header .filter-and-details .filters .custom-filter-select, .stream-detail-header .filter-and-details .filters .birthday-filter, .stream-detail-header .filter-and-details .filters .download-user-list{
    margin-bottom: 10px;
  }

  .stream-detail-header .filter-and-details .stream-detail ul.detail-list {
    width: 100% !important;
    padding-left: 0 !important;
  }

  .streaming-header-wrapper .streaming-filter {
    width: auto;
  }
  .user-header-streaming {
    flex-wrap: wrap;
    flex-direction: column-reverse;

    .downloads-button-wrapper {
      .add-session {
        width: 100%;

        .btn {
          width: 100%;
        }
      }
    }
  }
}