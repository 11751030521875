.fitness-plan-card {
    margin-bottom: 30px;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;

    .fitness-image-wrapper {
        height: 250px;
        overflow: hidden;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 200px;
            background: linear-gradient(rgba(255, 255, 255, 0.03), rgba(0, 0, 0, 0.6));
            z-index: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .video-wrapper {
            position: absolute;
            top: 10px;
            left: 15px;
            z-index: 10;
            padding: 5px;
            border-radius: 8px;
            background-color: #dedede;
        }

        .excercise-name {
            font-size: 18px;
            color: #ffffff;
            margin-bottom: 0;
            padding-bottom: 4px;
            // border-bottom: 1px solid #dedede;
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 9;
            padding: 15px;
        }
    }

    .fitness-card-content {
        padding: 5px 15px;

        .steps-detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0px;

            .aminities {
                color: #000000;
                padding-right: 5px;
                margin-bottom: 0;
                font-size: 12px;

                .label {
                    color: #898383;
                }

                .value {
                    color: #000000;
                }
            }
        }
    }

    .fitness-card-description {
        padding: 5px 15px;

        .description-heading {
            font-size: 14px;
            color: #898383;
        }

        .description-content {
            min-height: 160px;
            .desc-content {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: 12px;
            }

            .days-list {
                .days-heading {
                    font-size: 12px;
                    color: #898383;
                }

                .days-listing {
                    display: flex;
                    flex-wrap: wrap;

                    .day {
                        font-size: 10px;
                        background-color: #dedede;
                        border-radius: 4px;
                        margin-right: 5px;
                        display: flex;
                        padding: 0 5px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}