// desibled element
*:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}


/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a2a2a2;
}


// sticky-top-fixed
.sticky-tableHeader-fixed{
  position: sticky !important;
  top: 0;
  background-color: #ffffff;
  z-index: 9;
}

// custom
.custom__menu{
  z-index: 99 !important;
}
.width-200{
  width: 200px;
}
.width-150{
  width: 150px;
}
table{
  td{
    vertical-align: middle !important;
  }
}
.margin-bottom-10px{
  margin-bottom:10px;
}
.ant-tag-composition{
  .wrapper-overflow{
    white-space: normal;
    width: 300px;
    max-height: 100px;
    line-height: 2;
 }
}
.card-body{
  padding: 0.5rem;
}
.badge-pill{
  margin-right: 10px;
}
.card--dflex{
  justify-content: center;
  button{
    z-index: 11;
  }
}
.table{
  th{
    padding: 0.4rem;
  }
  td{
    padding: 0.4rem;
  }
}
.ant-input-group{
  .ant-input{
    &.width-70{
      width: 70px;
    }
  }
}
// popup
.modal-body-height{
  height: 450px;
  overflow: auto;
}
.modal-custom-bar{
  height: 450px;
  overflow-y:auto;
  overflow-x:hidden;
  padding-right: 10px;
}
.modal-custom-bar-margin{
  height: 450px;
  overflow-y:auto;
  overflow-x:hidden;
  padding-right: 10px;
  margin-right: 10px;
}
.modal-custom-max-bar-margin{
  max-height: 450px;
  overflow-y:auto;
  overflow-x:hidden;
  padding-right: 10px;
  margin-right: 10px;
}
.modal-width550{
  width: 550px;
}
.modal-header{
  align-items: center;
}
.modal-title h6{
  margin-bottom: 0;
}
.ant-form-item{
  margin-bottom: 14px !important;
}
.ant-form-item.mb-0{
  margin-bottom: 0 !important;
}
.ant-form-item-with-help{
  margin-bottom: 0px !important;
}
.invalid-feedback {
  font-size: 14px;
  color: #ff4d4f;

  .width-200 {
    width: 200px;
  }

  .width-150 {
    width: 150px;
  }

  table {
    td {
      vertical-align: middle !important;
    }
  }

  .margin-bottom-10px {
    margin-bottom: 10px;
  }

  .ant-tag-composition {
    .wrapper-overflow {
      white-space: normal;
      width: 300px;
      max-height: 100px;
      line-height: 2;
    }
  }

  .card-body {
    padding: 0.5rem;
  }

  .badge-pill {
    margin-right: 10px;
  }

  .card--dflex {
    justify-content: center;

    button {
      z-index: 11;
    }
  }

  .table {
    th {
      padding: 0.4rem;
    }

    td {
      padding: 0.4rem;
    }
  }

  .ant-input-group {
    .ant-input {
      &.width-70 {
        width: 70px;
      }
    }
  }

  // popup
  .modal-body-height {
    height: 450px;
    overflow: auto;
  }

  .modal-custom-bar {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }

  .modal-custom-bar-margin {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    margin-right: 10px;
  }

  .modal-custom-max-bar-margin {
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
    margin-right: 10px;
  }

  .modal-width550 {
    width: 550px;
  }

  .modal-header {
    align-items: center;
  }

  .modal-title h6 {
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 14px !important;
  }

  .ant-form-item.mb-0 {
    margin-bottom: 0 !important;
  }

  .ant-form-item-with-help {
    margin-bottom: 0px !important;
  }

  .invalid-feedback {
    font-size: 14px;
    color: #ff4d4f;
  }
}