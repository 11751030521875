@import './styles/colors.scss';
@import './styles/breakpoints.scss';

.users_header {
    .filter-user-date-wrapper {
        margin-bottom: 15px;

        .radio-custom-filter {
            margin-bottom: 10px;
        }
    }
}


.users_header .spinner-custom {
    position: absolute;
    right: 25px;
    top: 10px;
    z-index: 9;
    &.centered{
        top: 20px;
    }
}

.custom-table-view {
    height: calc(100vh - 300px);
    overflow: scroll;

    thead {
        tr:nth-child(1) th {
            background: white;
            position: sticky;
            top: 0;
            z-index: 5;
        }
    }

}

.pagination {
    margin-bottom: 0;
}

/* user profile */
/* tabs */
.tabs-wrapper {
    .graph-sidebar-nav {
        @media screen and (max-width: $sm) {
            // flex-direction: column;
            // margin-right: 30px;
            padding: 0;
        }

        .ant-tabs-nav {
            .ant-tabs-nav-wrap {
                @media screen and (max-width: $sm) {
                    overflow: auto;
                }

                .ant-tabs-tab {
                    @media screen and (max-width: $md) {
                        margin: 0;
                        padding: 0;
                    }

                    .ant-tabs-tab-btn {
                        @media screen and (max-width: $md) {
                            padding: 10px 12px;
                        }
                    }
                }
            }
        }

        .ant-tabs-tab-active {
            background-color: $themeColor;

            .Graph_Icons {
                color: $white;

                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }

    .graph-module {
        background-color: $lightBG;
        padding: 20px;

        .filter-tabs {
            .ant-tabs {
                .ant-tabs-tab {
                    padding: 10px 15px;

                    @media screen and (max-width: $md) {
                        padding: 0px;
                    }

                    &.ant-tabs-tab-active {
                        background-color: $black;
                        color: $white;
                        background-color: #000000;
                        color: #ffffff;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;

                        .ant-tabs-tab-btn {
                            color: $white;

                        }
                    }
                }
            }
        }


        .tabs-date-picker {
            margin-bottom: 10px;
        }

        .progress-section {
            .postion-rel {
                .graph-progress-custom {
                    .ant-progress-inner {
                        width: 140px !important;
                        height: 140px !important;

                        .ant-progress-circle {
                            width: 110px;
                        }
                    }
                }

                .progress-inner-icon {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .user-intake-detail {
            .left-drink {
                .circle {
                    background: #16d8e0;
                    width: 110px;
                    height: 110px;
                    border-radius: 100px;
                    position: relative;
                    position: relative;
                    margin: 0 auto;
                }

                p {
                    font-family: "FuturaMd";
                    font-size: 15px;
                }
            }
        }

        .graph-wrap {
            background-color: $white;
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
}

// diet-plan-wrapper
.diet-plan-heading {
    background: #dedede;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    .heading-title {
        font-size: 18px;
    }

    .value {
        margin-left: 10px;
    }
}

.diet-plan-wrapper {
    margin-top: 20px;

    .diet-plan-header {
        .diet-plan-header {
            font-size: 16px;
            margin-bottom: 20px;
            position: relative;
            padding-left: 10px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                height: 100%;
                width: 4px;
                background-color: #0d5a6c;
            }
        }

        .diet-plan-card-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 30px;

            @media(max-width: 991px) {
                margin-left: -5px;
                margin-right: -5px;
            }

            .diet-plan-card {
                width: calc((100% / 4) - 30px);
                box-shadow: 0px 3px 11px #858EA766;
                margin: 0 15px;
                border-radius: 10px;
                overflow: hidden;
                margin-bottom: 20px !important;

                @media(max-width: 1150px) {
                    width: calc((100% / 3) - 30px);
                }

                @media(max-width: 991px) {
                    margin: 0 5px;
                    width: calc((100% / 3) - 10px);
                }

                @media(max-width: 850px) {
                    margin: 0 5px;
                    width: calc((100% / 2) - 10px);
                }

                @media(max-width: 575px) {
                    margin: 0 5px;
                    width: calc((100% / 1) - 10px);
                }

                .food-hero-image {
                    height: 220px;
                    margin-bottom: 20px;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(rgba(255, 255, 255, 0.03), rgba(0, 0, 0, 0.6));
                    }

                    .image {
                        width: 100%;
                        max-height: 100%;
                        object-fit: cover;
                    }

                    .food-name-calori {
                        position: absolute;
                        width: 100%;
                        padding: 10px;
                        bottom: 0;
                        left: 0;
                        margin-bottom: 0;
                        z-index: 1;

                        .food-name {
                            color: #ffffff;
                            font-size: 16px;
                            margin-bottom: 3px;
                        }

                        .food-calori {
                            color: #ffffff;
                            font-size: 12px;
                            font-style: italic;
                            margin-bottom: 0;
                            opacity: 0.8;
                        }
                    }
                }

                .food-content {
                    padding: 0 10px;

                    .food-aminities {
                        display: flex;
                        flex-wrap: wrap;

                        .food-tiles {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 0 10px;
                            // border: 1px solid #dedede;
                            margin-right: 10px;
                            border-radius: 2px;
                            margin-bottom: 10px;

                        }

                        .table {
                            border-radius: 10px;

                            .food-label {
                                color: #9d9c9c;
                                margin-bottom: 0;
                                margin-right: 5px;
                                font-size: 10px;
                            }

                            .food-value {
                                margin-bottom: 0;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            // .ant-ribbon-wrapper{

            // }
        }
    }
}

// view Policy
.policy-wrapper {
    .policy-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        font-size: 12px;

        .detail-item {
            margin-right: 10px;
            border-radius: 15px;
            border: 1px solid #dedede;
            padding: 2px 10px;
            margin-bottom: 10px;

            .label {
                color: #333333;
                opacity: 0.5;
            }

            .value {
                color: #333333;
            }
        }
    }

    .policy-accordion-wrapper {
        .accordion {
            .card {
                .card-header {
                    padding: 0;

                    .btn-link {
                        display: block;
                        width: 100%;
                        text-align: left;
                        color: #333333;
                    }
                }
            }
        }
    }
}

// user details tabs ================================== starts=============================
.user-detail-headings {
    font-size: bold;
    width: 250px;
    background-color: #dedede;
    padding: 5px 10px;
    &.diet-track{
        background: #ffffff;
    }
    .sub-title-user-heading{
        font-size: 14px;
    }
}

// user logs
.user-ui-wrapper {
    margin-top: 30px;

    .user-ui-inner-wrapper {
        display: flex;
        flex-wrap: wrap;

        .user-navigation {
            width: 250px;
            padding-right: 20px;
            border-right: 1px solid #dedede;
        }

        .user-ui-content {
            padding-left: 30px;
            width: calc(100% - 250px);
            margin-left: auto;

            // logs
            .logs-items {
                background-color: #fafafa;
                padding: 10px;
                // margin-bottom: 10px;
                border-bottom: 2px solid #dedede;

                &:last-child {
                    border-bottom: 0px;
                }

                .logs-heading {
                    font-size: 20px;
                }

                .log-aminities {
                    font-size: 12px;
                    color: #333333;

                    .log-auther-date {
                        font-style: italic;

                        .label {
                            opacity: 0.5;
                        }

                        .value {}
                    }
                }
            }

            // questions
            .list-question-wrapper {
                .items-questions {
                    background-color: #fafafa;
                    padding: 10px;
                    border-bottom: 2px solid #dedede;

                    &:last-child {
                        border-bottom: 0;
                    }

                    .items-heading {
                        font-size: 20px;
                        color: #333333;
                        opacity: 0.5;
                    }

                    .items-answers {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

// service
.customization-inComment-box {
    .ant-comment-inner {
        align-items: center;
        padding-top: 0;

        .ant-comment-content {
            .ant-comment-content-author {
                margin-bottom: 0;
            }

            .ant-comment-content-detail {
                .content-detail {
                    line-height: normal;
                    font-size: 12px;
                }
            }
        }
    }
}

// bookschedule
.bookschedule {
    padding: 15px 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;

    .label-wrapper {
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .field-label {
        margin-bottom: 0;
        color: #ff6c0b
    }

    .schedule-card {
        border-radius: 10px;
        background-color: #ffffff;
        overflow: hidden;
        margin-bottom: 15px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        .scheduled-image-wrapper {
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f2f2f2;

            .schedule-card-image {
                max-width: 100%;
                max-height: 100%;
                object-fit: cover;
            }
        }

        .person-title {
            padding: 15px;
            text-align: center;
            font-size: 14px;
        }
    }
}

// slot table
.trainer-table-data{
    .table{
        table-layout: fixed;
        @media(max-width: 767px){
            table-layout: initial;
        }
        tr td{
            &:first-child{
                width: 140px;
            }
            &:nth-child(2){
                width: 220px;
            }
            &:nth-child(3){
                width: calc(100% - 360px);
            }
            .slot-column{
                display: flex;
                flex-wrap: wrap;
                .btn{
                    margin-top: 10px;
                }
            }
        }
    }
    .trainer-data-image{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
    .trainer-data{
        .title-trainer{
            font-size: 18px;
            margin: 0;
            margin-bottom: 10px;
        }
        .trainer-speciality,.trainer-rating,.trainer-fees{
            font-size: 14px;
            margin-bottom: 0px;
            min-width: 200px;
            .label{
                color: rgba(0, 0, 0, 0.6);
            }
            .value{
                font-weight: bold;
            }
        }
    }
}

.profile-btn-wrapper{
    .btn{
        font-size: 13px;
    }
}