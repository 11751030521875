.emoji-header{
    .search-platform{
        position: relative;
        max-width: 350px;
        min-width: 300px;
        .search-spiner{
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
        }
        .ant-select{
            height: 39px;
            .ant-select-selector{
                border:0;
                height: 36px;
                align-items: center;
            }
        }
    }
}

.image-icon{
    max-width: 30px;
    max-height: 30px;
    object-fit: cover;
}
.cursor-pointer{
    cursor: pointer;
}
.emoji-detail .emoji-image{
    width: 100px;
    height: 100px;
}

.emoji-detail .emoji-image img{
    max-width: 100%;
    max-height: 100%;
}

.emoji-action-icon{
    position: absolute;
    right: 0;
    top:0;
    height: 100%;
    width: 50px;
    background-color: #dedede;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


@media (max-width: 767px){
    .emoji-header{
        .search-platform{
            position: relative;
            max-width: 100%;
            min-width: 100%;
        }
    }
}