.main-wrapper {
    padding: 120px 100px 20px;
    height: 100vh;
    background-color: white;
}

.heightAuto{
    height: auto;
}

.checkboxHandle{
    position: relative;
    top: 2px;
    right: 5px;
}

.new-gym-manager-button {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.add-gym-button, .add-equipment-model-button {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 60px;
}

.gym-details-and-cta {
    display: flex;
}

.gym-details-cta {
    display: flex;
    margin-left: 100px;
    align-items: center;
}